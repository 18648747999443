import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  backgroundColorOne: string
  backgroundColorTwo: string
  badgeTitle: string
  bottomMargin?: number
  buttonName: string
  buttonUrl?: string
  colorPalette: ColorPalette
  colSizeOne: number
  colSizeTwo: number
  fontTextColor: string
  image: string
  imageTitle: string
  subtitle: string
  title: string
  titleTopLeft: string
  imageSubtitle?: string
  imageTitleSubtitle?: string
  asoEffect: string
}

/** const */
const HvacProductSection: React.FC<Props> = (props) => {
  return (
    <section
      className={`container-fluid text-left p-5 pt-5 pb-5`}
      style={{
        background: "#EFEFEF",
        color: "#365c70",
      }}
    >
      <div className="container">
        <div
          className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0"
          style={{ fontSize: "24px", color: "#365c70" }}
        >
          HVAC
        </div>

        <hr
          className="hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
            color: "",
          }}
        />


       <div class="container m-0 p-0 mt-3 mb-5 d-none d-sm-block">
          <div class="row">
            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/hvac/1.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>

            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/hvac/2.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>

            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/hvac/3.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>
          </div>
        </div>

       <div class="container m-0 p-0 d-block d-sm-none">
          <img
            data-aos="Fade"
            src="/hvac/2.jpg"
            loading="lazy"
            decoding="async"
            alt=""
            width="100%"
          />
        </div>


        <div className="contentHeroSpace hidden-xs"></div>

        <div className="row">
         <div
           className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0"
           style={{ fontSize: "18px", color: "#365c70" }}
         >
         </div>

         <div
            className="hidden-sm hidden-md hidden-lg hidden-xl mb-5 p-0"
            style={{ fontSize: "18px", color: "" }}
          >
            Norhart Supply carries multiple products for heating and cooling systems such as furnaces, bath fans, ductwork, thermostats and air filters.  Your heating, cooling and air system is one of the most important assets to your home and business, at Norhart Supply we make it a priority to supply the components of these systems to keep your summers cool and your winters warm.

            <p/>

            <strong>Furnaces</strong>
            <br/>
            Furnaces provide heat your home, building or business.  Furnaces generate heat, thus heating air and distributing the heated air throughout a space using ducts.  Ductwork is a key component to this process and works together with your furnace.  In cooler climates, furnaces are an important item to have in your building.
            <p/>
            <hr/>

            <strong>Bath fans</strong>
            <br/>
            The bath fans come with great features. The bath fans we carry are very quiet, and do not disturb those in surrounding areas.  Even with the reduced noise, these fans are just as effective as any other.  Bath fans are great to reduce steam from hot water.
            <p/>
            <hr/>

            <strong>Ductwork</strong>
            <br/>
            In order to complete your HVAC system, you need ductwork, another material Norhart Supply carries.  Ductwork carries air throughout a building, both heated and cooled.  Ductwork is an important asset in the ventilation system of a building or home.  The HVAC unit connected to your ductwork filters the air before sending it through a space.
            <p/>
            <hr/>

            <strong>Thermostats</strong>
            <br/>
            Norhart Supply carries thermostats to regulate your building or home's temperature.  This device detects temperature changes in order to maintain the temperature of an enclosed room or area at an almost constant rate.  The thermostat typically generates electrical signals when the temperature exceeds or falls under the ideal temperature.  A thermostat is a necessity in any HVAC system.
            <p/>
            <hr/>

            <strong>Air filters</strong>
            <br/>
            The variety of air filters to keep your heating and cooling system blowing clean air.  Air filters filter pollutants and contaminates out of the air before it goes through the ductwork and into your living space.  It is recommended to change, or clean, the air filters every 1-3 months.  Keeping your air filters clean keeps the air circulating in your home clean.
            <p/>

            </div>
        </div>
      </div>
    </section>
  )
}

export default HvacProductSection
